import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { GlobalStyle } from "../assets/GlobalStyles";
import { GatsbyImage } from "gatsby-plugin-image";
import PhotoPageLayout from "../components/PhotoPageLayout";

const H = (props) => {
  const assets = props.data.allContentfulPortfolioPhotos.edges;

  return (
    <>
      <GlobalStyle />
      <PhotoPageLayout url={props.path}>
        {assets.map(
          ({
            node: {
              id,
              image: { title, gatsbyImageData },
            },
          }) => (
            <StyledImageWrapper key={id}>
              <GatsbyImage image={gatsbyImageData} alt={title} />
            </StyledImageWrapper>
          )
        )}
      </PhotoPageLayout>
    </>
  );
};

export default H;

const StyledImageWrapper = styled.div`
  margin: 2em;
  @media (max-width: 920px) {
    margin: 1em;
  }
`;

export const query = graphql`
  query {
    allContentfulPortfolioPhotos(
      filter: { pageForSortingExTRISH: { eq: "H" } }
    ) {
      edges {
        node {
          id
          image {
            gatsbyImageData
            title
          }
        }
      }
    }
  }
`;
